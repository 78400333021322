import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"
import Heading from "../elements/heading"
import Modal from "../elements/video_modal"
import Card from "../elements/card"

// Images
import playButton from "../images/play_button.png"

const videoLinks = [
  "qd5oc9hLrXg", //Blackjack
  "v8dqu_aVQk8", //7 Card Hold'em
  "juMyMuf7Cdo", //Baccarat
  "JR5JNVxZolU", //Roulette
  "bOyZbYjUcZg", //Caribbean Stuf
  "fCMEnxSjkYg", //3 Card Mini Poker
  "PR9LPKic9IA", //aztec glory
  "OZuQ4EWFzN0", //lucky clover
  "VpsJRNkBeYE", //helena
  "BRbO4h2OVLE", //fruit zen
  "gEMIRTOTtyU", //happy serengeti
  "H-yNo34wDlU", //flame dancer
]

const CasinoPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contentfulEventPost(slug: { eq: "roulette-mania" }) {
        title
        slug
        subtitle
        description
        image {
          fluid(maxWidth: 220, maxHeight: 300) {
            src
          }
        }
      }
    }
  `)
  const post = data.contentfulEventPost

  const handleSelected = e => {
    const games = document.getElementsByClassName("casino__item")
    if (e.target.classList.contains("selected")) {
      e.target.classList.remove("selected")
      return
    }

    for (let i = 0; i < games.length; i++) {
      games[i].classList.remove("selected")
    }
    e.target.classList.add("selected")
  }
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "casino" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="New Macau Casino features 14 Gaming Tables and over 30 Slot Machines. Spin the reels and test your luck!"
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "casino_title" })}
        sliderSubtitle={intl.formatMessage({ id: "casino_subtitle" })}
      />
      <Subscribe />

      <div className="games__wrapper">
        <Heading
          title={intl.formatMessage({ id: "games_heading" })}
          description={intl.formatMessage({ id: "games_description" })}
          id="heading1"
        />

        <div className="casino casino__games casino___update">
          {/* <Card
            className="casino__itemCard"
            image={post.image.fluid}
            title={post.title}
            subtitle={post.subtitle}
            description={post.description}
            buttonLink={`/events/${post.slug}`}
          /> */}
          <div className="casino__item casino__item1" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game1" })}</h1>
              <p>{intl.formatMessage({ id: "game1_desc" })}</p>
              <Modal
                videoUrl={videoLinks[0]}
                title={intl.formatMessage({ id: "game1" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game1" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item2" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game2" })}</h1>
              <p>{intl.formatMessage({ id: "game2_desc" })}</p>
              <Modal
                videoUrl={videoLinks[1]}
                title={intl.formatMessage({ id: "game2" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game2" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item3" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game3" })}</h1>
              <p>{intl.formatMessage({ id: "game3_desc" })}</p>
              <Modal
                videoUrl={videoLinks[2]}
                title={intl.formatMessage({ id: "game3" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game3" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item4" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game4" })}</h1>
              <p>{intl.formatMessage({ id: "game4_desc" })}</p>
              <Modal
                videoUrl={videoLinks[3]}
                title={intl.formatMessage({ id: "game4" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game4" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item5" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game5" })}</h1>
              <p>{intl.formatMessage({ id: "game5_desc" })}</p>
              <Modal
                videoUrl={videoLinks[4]}
                title={intl.formatMessage({ id: "game5" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game5" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item6" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "game6" })}</h1>
              <p>{intl.formatMessage({ id: "game6_desc" })}</p>
              <Modal
                videoUrl={videoLinks[5]}
                title={intl.formatMessage({ id: "game6" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "game6" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="games__wrapper">
        <Heading
          title={intl.formatMessage({ id: "slots_heading" })}
          description={intl.formatMessage({ id: "slots_description" })}
        />
        <div className="casino casino__slots">
          <div className="slots__image" />
          <div className="casino__item casino__item7" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot1" })}</h1>
              <p>{intl.formatMessage({ id: "slot1_desc" })}</p>
              <Modal
                videoUrl={videoLinks[6]}
                title={intl.formatMessage({ id: "slot1" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot1" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item8" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot2" })}</h1>
              <p>{intl.formatMessage({ id: "slot2_desc" })}</p>
              <Modal
                videoUrl={videoLinks[7]}
                title={intl.formatMessage({ id: "slot2" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot2" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item9" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot3" })}</h1>
              <p>{intl.formatMessage({ id: "slot3_desc" })}</p>
              <Modal
                videoUrl={videoLinks[8]}
                title={intl.formatMessage({ id: "slot3" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot3" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item10" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot4" })}</h1>
              <p>{intl.formatMessage({ id: "slot4_desc" })}</p>
              <Modal
                videoUrl={videoLinks[9]}
                title={intl.formatMessage({ id: "slot4" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot4" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item11" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot5" })}</h1>
              <p>{intl.formatMessage({ id: "slot5_desc" })}</p>
              <Modal
                videoUrl={videoLinks[10]}
                title={intl.formatMessage({ id: "slot5" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot5" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
          <div className="casino__item casino__item12" onClick={handleSelected}>
            <div className="casino__content">
              <h1>{intl.formatMessage({ id: "slot6" })}</h1>
              <p>{intl.formatMessage({ id: "slot6_desc" })}</p>
              <Modal
                videoUrl={videoLinks[11]}
                title={intl.formatMessage({ id: "slot6" })}
                description={`${intl.formatMessage({
                  id: "how_to_play",
                })} ${intl.formatMessage({ id: "slot6" })}`}
                modalButton={<img src={playButton} alt="play" />}
                className="casino__modalButton"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(CasinoPage)
